<template>
  <div class="alert-wrap"  id="calendar-alert">
    <div class="resp-wrap" v-if="calAlert== true">
      <div style="width:100%;">
        <img :src="publicOrganizationData.logoColor[0].url" alt="" style="width:200px; display:block; margin:0 auto 10px;">
      </div>
      <div class="resp-body">
        <div class="envelop" style="display:block;">
          <h5>Your request for <strong>{{record.calAtt.name.attributeValue}}</strong>  appointment was received</h5>
          <p>Look for a confirmation in your inbox or phone message.</p>
        </div>
        <div class="envelop">
          <svg xmlns="http://www.w3.org/2000/svg" width="0.88em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 448 512"><path fill="#5a5a5a" d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"/></svg>
          <span> {{ setAlert('date',record.item.attributes ) }} ({{record.timeZone}})</span> 
          <!--span>{{new Date(record.item.attributes.date.attributeValue )}}</!--span-->
        </div>
        <div class="envelop">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path fill="#5a5a5a" d="M24 13h-2a3.003 3.003 0 0 0-3-3V8a5.006 5.006 0 0 1 5 5Z"/><path fill="#5a5a5a" d="M28 13h-2a7.008 7.008 0 0 0-7-7V4a9.01 9.01 0 0 1 9 9zm-7.667 8.482l2.24-2.24a2.167 2.167 0 0 1 2.337-.48l2.728 1.092A2.167 2.167 0 0 1 29 21.866v4.961a2.167 2.167 0 0 1-2.284 2.169C7.594 27.806 3.732 11.61 3.015 5.408A2.162 2.162 0 0 1 5.169 3h4.873a2.167 2.167 0 0 1 2.012 1.362l1.091 2.728a2.167 2.167 0 0 1-.48 2.337l-2.24 2.24s1.242 8.732 9.908 9.815z"/></svg>
          <span>Contact Us {{formatData('phone',record.calAtt.informationPhone.attributeValue)}}</span>
        </div>
        <div class="envelop">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#25be00" fill-rule="evenodd" d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11s11-4.925 11-11S18.075 1 12 1Zm4.768 9.14a1 1 0 1 0-1.536-1.28l-4.3 5.159l-2.225-2.226a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.475-.067l5-6Z" clip-rule="evenodd"/></svg>  
          <span>{{record.calInstructions}}</span>
        </div>
      </div>
      <ui-button style="width:200px; padding: 10px 20px; background:#1e9800;" :text="'Close'" v-on:click="close()" />
    </div>
  </div>
  <section class="public-wrap">
    
    <div v-if="record != null" class="smart-wrap container-in shadowBox" :style=" record.bodyStyle">
      <div style="width:100%;">
        <img :src="publicOrganizationData.logoColor[0].url" alt="" style="width:200px; display:block; margin:0 auto 10px;">
      </div>
      <h4 style="text-align:center; width:100%;">{{record.calendarName}}</h4>
      <paper-layout v-if="record != null" layoutCategory="quiz" id="layout" :layout="record.layout" :data="record.item" :record="record" :models="record.item.attributes" :parent="publicOrganization"/>
    </div>
  </section>

</template>
<script>
  let tokenQuote = null
  
  //Tools
  
  import { API, Storage, Auth} from 'aws-amplify'
  import Vuex from "vuex";
  import Swal from 'sweetalert2'
  import { calendar, loading, popUp, formatData} from '../../store/tools'
  
  //import html2pdf from 'html2pdf'
  //Components
  import PaperDisplayer from '../../components/papers/PaperDisplayer.vue'
  import PaperLayout from '../../components/papers/PaperLayout.vue'
  import UiButton from '../../components/ui/UiButton.vue';
  //GRAPHQL
  import { smd_updateSmartRecord } from '../../graphql/mutations'
  import { smd_getSMLItem, smd_getSMLItemByName , smd_getCALItem } from '../../graphql/queries'

  export default {
    name:'checkout',
    components:{
      PaperDisplayer,
      UiButton,
      PaperLayout,
    },
    data() {
      return {
        record:null,
        response: null,
        
      }
    },
    created() {
      this.setPublicOrganization(undefined)
      this.setPublicOrganizationData(undefined)
      loading('show')
      this.setUserWeb()
      /*if (this.reload == true){
        this.setStatusReload(false);
        location.reload();
      }*/
      
    },
    methods: {
      ...Vuex.mapActions(['getPublicOrganization','getPublicOrganizationData']),
      ...Vuex.mapMutations(['setStatusReload','setPublicOrganizationData','setPublicOrgPay','setPublicOrganization','setContactSK','setWebSite','SetUser']),
      formatData(format, data){
        return formatData(format, data)
      },
      //View  
      close(){
        window.close();
      },
      async setUserWeb(){
        loading('show')
        this.record = null
        console.log('>>> USER: '+this.user);
        Auth.signIn('smd_webuser', '383@JDU45ss#')
        .then((user) => { 
            this.SetUser(user.username)
            this.getItem()
        })
        .catch((err) => popUp(pullData.smd_response, 'response', 'err'));
        loading('hidde')
        
        
      },

      async getItem(   ) { 
        loading('show', 'getItem')
        //console.clear()
        let pullData = ''
        let ORGPK = this.publicOrganization
        let SMLPK = this.publicOrganization
        console.log(this.$router.currentRoute.value.query);
        if (this.$router.currentRoute.value.query.ORGPK != undefined) { 
          ORGPK = this.$router.currentRoute.value.query.ORGPK
          this.getPublicOrganization()
        }else{
          console.log('Here begin ALIAS');
          console.time()
          await this.getPublicOrganization()//Alias
          console.timeEnd()
          ORGPK = this.publicOrganization
        }
        if (this.$router.currentRoute.value.query.SMLPK != undefined) {
          SMLPK = this.$router.currentRoute.value.query.SMLPK
        }else{
          SMLPK = ORGPK
        }
        let query = null
        console.time()
        try {
          query = {
            itemPK: ORGPK,
            itemSK: this.$router.currentRoute.value.query.SK,
            SMLPK: SMLPK,
            SMLSK: this.$router.currentRoute.value.query.SMLSK,
            parent: undefined,
            entity: {PK: '#SMARTDASH', SK: '#ENT#961aa971-3017-4f13-8f52-0b3d513313a3'},
            calendar:this.$router.currentRoute.value.query.calendar
          }
          console.log(query);
          pullData = await API.graphql({
            query: smd_getCALItem,
            variables: query
          }); 
          
          pullData = pullData.data.smd_getCALItem
          console.log("--> Data of Query Before :", pullData);

          //Proccess Data
          pullData.layout =  JSON.parse(pullData.data)
          pullData.item =  JSON.parse(pullData.item)
          
          pullData.attStr =  JSON.stringify(pullData.item.attributes)
          console.log("--> Data of Query After :", pullData);
          delete pullData.data
          let SML = {}
          let SMLData =JSON.parse(pullData.SML).attributes
          for (const key in SMLData) {
            try {
              if (SMLData[key].attributeType == 'L') {
                SMLData[key].attributeValue =JSON.parse(SMLData[key].attributeValue)
              }
            } catch (error) {
            }
            SML[SMLData[key].attributeName] =SMLData[key].attributeValue
          }
          delete pullData.data
          this.record = Object.assign(pullData, SML) 
          this.record.item.attributes.calendar.attributeValue = this.$router.currentRoute.value.query.calendar
          this.record.item.attributes.timeZone.attributeValue = this.record.timeZone
          this.record.bookings = JSON.parse(this.record.bookings)
          this.record.availability = JSON.parse(this.record.availability )
          console.log(this.record );
          document.head.insertAdjacentHTML("beforeend", this.record.fontScript)
        } catch (error) {
          popUp(error, 'cach', 'err')
          console.log(error);
        }
        this.record.item.attributes.subject.attributeValue=this.record.calendarName
        this.record.item.attributes.calInstructions.attributeValue=this.record.calInstructions
        this.record.calAtt = JSON.parse(this.record.calendarAtts)
        this.setWebSite(this.record)
        console.timeEnd()
        document.getElementById('metaTitle').content = this.record.calAtt.name.attributeValue
        this.sub= false
        loading('hidde', 'getItem')
      },
      setAlert(action, data){
        function setSufijoDate(time) {
          let meet = time.split(':')
          let resp = meet[0]+':'+meet[1] +'am'
          let hr = parseInt(meet[0])
          if(hr > 11){
            if (hr>12) {
              resp = meet[0]-12+':'+meet[1] +'pm'
            } else{
              resp = meet[0]+':'+meet[1] +'pm'
            }
          }  
          return resp
        }
        switch (action) {
          case 'date':
            try {
              let intHours = parseInt(data.duration.attributeValue) /60
              let date = new Date('February 10, 2022 '+ data.time.attributeValue) 
              var numberOfMlSeconds = date.getTime();
              var addMlSeconds = (intHours * 60) * 60000;
              let newDateObj = new Date(numberOfMlSeconds + addMlSeconds);
              newDateObj = newDateObj.toLocaleTimeString()

              return setSufijoDate(data.time.attributeValue) +" - "+ setSufijoDate(newDateObj.slice(0,5)) + ", " + new Date(data.date.attributeValue).toUTCString().slice(0,16)

            } catch (error) {
              console.log(error);
            }
          break;
          case 'close':
            location.reload()
          break;
        }
      }
      //Complete

    },
    computed:{
      ...Vuex.mapState(['publicOrganization', 'publicOrganizationData','publicOrganization', 'reload', 'user','publicOrgPay','dataUser','quiz','organizationData','calAlert'])
    }
  }
</script>
<style lang="less">
  body{
    background: #f0f0f0;
  }
  .alert-wrap{
    width: 100%;
    height: 100vh;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: #0000006f;
    position: fixed;
    opacity: 0;
    z-index: -1;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    &.show{
      opacity: 1;
      z-index: 999;
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -ms-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
    }
    .resp-wrap{
      background: #fff;
      border-radius: 20px;
      max-width: 800px;
      width: 100%;
      height: auto;
      padding: 20px;
      color: #5A5A5A;
      h4{
        margin: 10px 0 0 0;
        font-weight: 600;
      }
      p{
        font-weight: 500;
        margin: 10px 0 0 0;
      }
      .resp-body{
        margin: 20px 0 10px;
        padding: 0 0 20px 0;
        .envelop{
          padding: 20px 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid #d9d9d9;
          &.last{
            border:none;
          }
        }
        svg{
          width: 20px;
          height: 20px;
          margin: 0 10px 0 0;
        }
        span{
          font-weight: 600;
        }
      }
    }
  }
</style>